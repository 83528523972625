/* You can add global styles to this file, and also import other style files */

@import "./assets/scss/app.scss";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

:host {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}


/* @tarique_alam */

.btn-height {
    margin-top: 15px;
    padding: 10px 0;
}

.page-wrapper .page-header .header-wrapper .nav-right ul li.notify {
    margin-right: 25px !important;
}

.profile-media img {
    width: 37px;
    height: 37px;
    object-fit: cover;
    margin-top: -11px;
}

.left-header .level-menu .nav-link {
    margin-left: 0 !important;
}

.page-wrapper .sidebar-main-title h6 {
    margin-bottom: 0 !important;
}

.page-wrapper .sidebar-main-title div {
    background-color: #dad6ff !important;
}

.form-control {
    height: 41px;
    padding: 0.475rem 0.75rem;
    border: 1px solid #dddddd;
}

.form-select {
    background-image: url(../src/assets/images/dropdown-icon.svg);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    appearance: none;
    border-color: #efefef;
    background-color: #fff;
    font-size: 14px;
    color: #898989;
}

.topSellingItem {
    color: #7366ff;
    font-weight: 500;
    margin-bottom: 25px;
}

.table thead tr {
    border-bottom: 1px solid #dddddd;
}

.container-fluid {
    padding: 0;
}

.pdlr {
    padding: 0 15px;
}

.table th,
.table td {
    padding: 4px 4px;
    font-size: 12px;
    color: #000000;
    vertical-align: middle;
}

.table thead {
    background-color: #dad6ff;
}

.table thead tr th {
    padding: 10px 4px;
}
.trlistBtn {
    margin: 0px 0;
    display: flex;
    justify-content: left;
}
.trlistBtn .edit-btn {
    border: none;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    color: #7366ff;
}
.trlistBtn .delete-btn{
    border: none;
    font-size: 12px;
    padding: 4px 10px;
    border-radius: 4px;
    background-color: #efefef;
    color: #ff527f;
    margin-left: 7px;
}

textarea:hover,
//input:hover,
textarea:active,
input:active,
textarea:focus,
//input:focus,
select:focus,
button:focus,
button:active,
button:hover,
label:focus,
.form-control:focus,
.btn:active,
.btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
    border-color: #e2e2e2 !important;
}

.custom-datatable .ngx-datatable.bootstrap .datatable-header {
    border: 1px solid #efefef;
    border-bottom: none;
    background-color: #dad6ff;
    color: black;
    font-weight: 500;
}
.datatable-body-row {
    &.datatable-row-odd {
      &:hover {
        background-color: #7366ff !important;
      }
    }
  }
.live_data_btn {
    padding: 0.5rem 0.55rem;
}

@media screen and (max-width: 768px) {
    .form-control {
        padding: 0.475rem 0.1rem;
    }
    .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
    }
    select {
        padding: 0.475rem 0.75rem !important;
    }
    .faq-form input {
        padding: 0.475rem 0.75rem !important;
    }
}